import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import "./App.scss";
import Home from "./components/Home";
import About from "./components/About";
import Security from "./components/Security";
import Nodes from "./components/Node";
import Contact from "./components/Contact";
import ServiceTerra from "./components/Services/ServiceTerra";
import ServiceSei from "./components/Services/ServiceSei";
import ServiceZetachaintest from "./components/Services/ServiceZetachaintest";
import ServiceTenettest from "./components/Services/ServiceTenettest";
import ServiceAndromedatest from "./components/Services/ServiceAndromedatest";
import ServiceElystest from "./components/Services/ServiceElystest";
import ServiceMeme from "./components/Services/ServiceMeme";
import ServiceOraichain from "./components/Services/ServiceOraichain";
import ServiceAcrechain from "./components/Services/ServiceAcrechain";
import ServiceCanto from "./components/Services/ServiceCanto";
import ServicePlanq from "./components/Services/ServicePlanq";
import ServiceWhitewhale from "./components/Services/ServiceWhitewhale";
import ServiceStride from "./components/Services/ServiceStride";
import ServiceKujira from "./components/Services/ServiceKujira";
import ServiceMars from "./components/Services/ServiceMars";
import ServiceAxelar from "./components/Services/ServiceAxelar";
import ServiceTenet from "./components/Services/ServiceTenet";
import ServicePersistence from "./components/Services/ServicePersistence";
import ServicePersistencetest from "./components/Services/ServicePersistencetest";
import ServiceProvenance from "./components/Services/ServiceProvenance";
import ServiceInjective from "./components/Services/ServiceInjective";
import ServiceHaqq from "./components/Services/ServiceHaqq";
import ServiceHaqqtest from "./components/Services/ServiceHaqqtest";
import ServiceDydx from "./components/Services/ServiceDydx";
import ServiceCelestia from "./components/Services/ServiceCelestia";
import ServiceCelestiatest from "./components/Services/ServiceCelestiatest";
import ServiceAndromeda from "./components/Services/ServiceAndromeda";
import ServiceFurya from "./components/Services/ServiceFurya";
import ServiceUniontest from "./components/Services/ServiceUniontest";
import ServicePryzmtest from "./components/Services/ServicePryzmtest";
import ServiceCheqd from "./components/Services/ServiceCheqd";
import ServiceBerachaintest from "./components/Services/ServiceBerachaintest";
import ServiceZetachain from "./components/Services/ServiceZetachain";
import ServiceSidetest from "./components/Services/ServiceSidetest";
import ServiceSedatest from "./components/Services/ServiceSedatest";
import ServiceJunctiontest from "./components/Services/ServiceJunctiontest";
import ServiceSeda from "./components/Services/ServiceSeda";
import ServiceCrossfitest from "./components/Services/ServiceCrossfitest";
import ServiceSource from "./components/Services/ServiceSource";
import ServiceNeutaro from "./components/Services/ServiceNeutaro";
import ServiceNibiru from "./components/Services/ServiceNibiru";
import ServiceBlockx from "./components/Services/ServiceBlockx";
import ServicePryzm from "./components/Services/ServicePryzm";
import ServiceInitiatest from "./components/Services/ServiceInitiatest";
import ServiceBerachainV2testnet from "./components/Services/ServiceBerachainV2testnet";
import ServicePenumbra from "./components/Services/ServicePenumbra";
import ServiceSoarchaintestnet from "./components/Services/ServiceSoarchaintestnet";
import ServiceLaconictestnet from "./components/Services/ServiceLoconictestnet";
import ServiceStoryiliad from "./components/Services/ServiceStoryiliad";
import ServiceAxonetest from "./components/Services/ServiceAxonetest";
import ServiceRouter from './components/Services/ServiceRouter';
import ServiceZenrocktestnet from './components/Services/ServiceZenrocktestnet';
import ServiceSunrisetestnet from './components/Services/ServiceSunrisetestnet';
import ServicePrysmtest from './components/Services/ServicePrysmtest';
import ServiceCrossfi from './components/Services/ServiceCrossfi';
import ServiceGalacticatest from './components/Services/ServiceGalacticatest';
import ServiceStorytestnet from './components/Services/ServiceStorytestnet';
import ServiceNamada from './components/Services/ServiceNamada';
import ServiceElys from './components/Services/ServiceElys';
import ServiceSide from './components/Services/ServiceSide';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";

function App() {
  return (
    <Router>
      <div className="App">
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/security" element={<Security />} />
          <Route path="/nodes" element={<Nodes />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/service/terra" element={<ServiceTerra />} />
          <Route path="/service/tenet" element={<ServiceTenet />} />
          <Route path="/service/axelar" element={<ServiceAxelar />} />
          <Route path="/service/mars" element={<ServiceMars />} />
          <Route path="/service/injective" element={<ServiceInjective />} />
          <Route path="/service/kujira" element={<ServiceKujira />} />
          <Route path="/service/stride" element={<ServiceStride />} />
          <Route path="/service/whitewhale" element={<ServiceWhitewhale />} />
          <Route path="/service/persistence" element={<ServicePersistence />} />
          <Route path="/service/persistencetest" element={<ServicePersistencetest />} />
          <Route path="/service/provenance" element={<ServiceProvenance />} />
          <Route path="/service/planq" element={<ServicePlanq />} />
          <Route path="/service/canto" element={<ServiceCanto />} />
          <Route path="/service/acrechain" element={<ServiceAcrechain />} />
          <Route path="/service/oraichain" element={<ServiceOraichain />} />
          <Route path="/service/meme" element={<ServiceMeme />} />
          <Route path="/service/sei" element={<ServiceSei />} />
          <Route path="/service/haqq" element={<ServiceHaqq />} />
          <Route path="/service/elystest" element={<ServiceElystest />} />
          <Route path="/service/andromedatest" element={<ServiceAndromedatest />} />
          <Route path="/service/tenettest" element={<ServiceTenettest />} />
          <Route path="/service/zetachaintest" element={<ServiceZetachaintest />} />
          <Route path="/service/zetachain" element={<ServiceZetachain />} />
          <Route path="/service/haqqtest" element={<ServiceHaqqtest />} />
          <Route path="/service/dydx" element={<ServiceDydx />} />
          <Route path="/service/celestia" element={<ServiceCelestia />} />
          <Route path="/service/celestiatest" element={<ServiceCelestiatest />} />
          <Route path="/service/andromeda" element={<ServiceAndromeda />} />
          <Route path="/service/furya" element={<ServiceFurya />} />
          <Route path="/service/uniontest" element={<ServiceUniontest />} />
          <Route path="/service/pryzmtest" element={<ServicePryzmtest />} />
          <Route path="/service/berachaintest" element={<ServiceBerachaintest />} />
          <Route path="/service/berachain-v2-testnet" element={<ServiceBerachainV2testnet />} />
          <Route path="/service/sidetest" element={<ServiceSidetest />} />
          <Route path="/service/seda" element={<ServiceSeda />} />
          <Route path="/service/pryzm" element={<ServicePryzm />} />
          <Route path="/service/sedatest" element={<ServiceSedatest />} />
          <Route path="/service/cheqd" element={<ServiceCheqd />} />
          <Route path="/service/crossfitest" element={<ServiceCrossfitest />} />
          <Route path="/service/source" element={<ServiceSource />} />
          <Route path="/service/neutaro" element={<ServiceNeutaro />} />
          <Route path="/service/nibiru" element={<ServiceNibiru />} />
          <Route path="/service/blockx" element={<ServiceBlockx />} />
          <Route path="/service/junctiontest" element={<ServiceJunctiontest />} />
          <Route path="/service/initiatest" element={<ServiceInitiatest />} />
          <Route path="/service/penumbra" element={<ServicePenumbra />} />
          <Route path="/service/soarchaintest" element={<ServiceSoarchaintestnet />} />
          <Route path="/service/laconictest" element={<ServiceLaconictestnet />} />
          <Route path="/service/storyiliad" element={<ServiceStoryiliad />} />
          <Route path="/service/storytestnet" element={<ServiceStorytestnet />} />
          <Route path="/service/axonetest" element={<ServiceAxonetest />} />
          <Route path="/service/router" element={<ServiceRouter />} />
          <Route path="/service/zenrocktestnet" element={<ServiceZenrocktestnet />} />
          <Route path="/service/sunrisetestnet" element={<ServiceSunrisetestnet />} />
          <Route path="/service/prysmtestnet" element={<ServicePrysmtest />} />
          <Route path="/service/crossfi" element={<ServiceCrossfi />} />
          <Route path="/service/galacticatestnet" element={<ServiceGalacticatest />} />
          <Route path="/service/namada" element={<ServiceNamada />} />
          <Route path="/service/elys" element={<ServiceElys />} />
          <Route path="/service/side" element={<ServiceSide />} />
        </Routes>
      </HelmetProvider>
      </div>
    </Router>
  );
}

export default App;
