import "../../styles/Home/Mainnet.scss";
import elysimage from "../../images/test1.png";
import image1 from "../../images/item1.png";
import image2 from "../../images/item2.png";
import image3 from "../../images/item3.png";
import image4 from "../../images/item4.png";
import image5 from "../../images/item5.png";
import image6 from "../../images/item6.png";
import image7 from "../../images/item7.png";
import image8 from "../../images/item8.png";
import image9 from "../../images/item9.png";
import image10 from "../../images/item10.png";
import image11 from "../../images/item11.png";
import image12 from "../../images/item12.png";
import image13 from "../../images/item13.png";
import image14 from "../../images/item14.png";
import image15 from "../../images/item15.png";
import zetaimage from "../../images/test5.png";
import furya from "../../images/furya.png";
import namadaimg from "../../images/namada.png";
import seiimage from "../../images/sei.png";
import haqqimage from "../../images/haqq.png";
import andromeda from "../../images/test2.png";
import celestiaimage from "../../images/celestia.png";
import persistenceimage from "../../images/persistence.png";
import injectiveimg from "../../images/injective.png";
import cheqdimage from "../../images/cheqd.png";
import sourceimage from "../../images/source.png";
import nibiruimage from "../../images/nibiru.jpg";
import neutaroimage from "../../images/neutaro.png";
import blockximage from "../../images/blockx.png";
import sedaimage from "../../images/seda.png";
import pryzmimage from "../../images/pryzm.png";
import penumbraimage from "../../images/penumbra.png";
import routerimage from "../../images/router.png";
import crossfiimg from "../../images/crossfi.png";
import sideimage from "../../images/side.png";
import close from "../../images/modal_close.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { values } from '../../components/Chains/values';

const Mainnet = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [myimg, setImage] = useState('');
  const [commission, setCommission] = useState('');
  const [valoper, setValoper] = useState('');
  const [link, setLink] = useState('');

  const handleOpenSui = () => {
    setModalOpen(true);
    setName(values['sui'].name);
    setImage(values['sui'].image);
    setCommission(values['sui'].commission);
    setValoper(values['sui'].valoper);
    setLink(values['sui'].link);
  };

  const handleOpenTenet = () => {
    setModalOpen(true);
    setName(values['tenet'].name);
    setImage(values['tenet'].image);
    setCommission(values['tenet'].commission);
    setValoper(values['tenet'].valoper);
    setLink(values['tenet'].link);
  };

  const handleOpenAcrechain = () => {
    setModalOpen(true);
    setName(values['acrechain'].name);
    setImage(values['acrechain'].image);
    setCommission(values['acrechain'].commission);
    setValoper(values['acrechain'].valoper);
    setLink(values['acrechain'].link);
  };
  
  const handleOpenAxelar = () => {
    setModalOpen(true);
    setName(values['axelar'].name);
    setImage(values['axelar'].image);
    setCommission(values['axelar'].commission);
    setValoper(values['axelar'].valoper);
    setLink(values['axelar'].link);
  };

  const handleOpenSide = () => {
    setModalOpen(true);
    setName(values['side'].name);
    setImage(values['side'].image);
    setCommission(values['side'].commission);
    setValoper(values['side'].valoper);
    setLink(values['side'].link);
  };  
  
  const handleOpenCanto = () => {
    setModalOpen(true);
    setName(values['canto'].name);
    setImage(values['canto'].image);
    setCommission(values['canto'].commission);
    setValoper(values['canto'].valoper);
    setLink(values['canto'].link);
  };

  const handleOpenElys = () => {
    setModalOpen(true);
    setName(values['elys'].name);
    setImage(values['elys'].image);
    setCommission(values['elys'].commission);
    setValoper(values['elys'].valoper);
    setLink(values['elys'].link);
  };  
  
  const handleOpenKujira = () => {
    setModalOpen(true);
    setName(values['kujira'].name);
    setImage(values['kujira'].image);
    setCommission(values['kujira'].commission);
    setValoper(values['kujira'].valoper);
    setLink(values['kujira'].link);
  };
  
  const handleOpenMars = () => {
    setModalOpen(true);
    setName(values['mars'].name);
    setImage(values['mars'].image);
    setCommission(values['mars'].commission);
    setValoper(values['mars'].valoper);
    setLink(values['mars'].link);
  };
  
  const handleOpenMeme = () => {
    setModalOpen(true);
    setName(values['meme'].name);
    setImage(values['meme'].image);
    setCommission(values['meme'].commission);
    setValoper(values['meme'].valoper);
    setLink(values['meme'].link);
  };
  
  const handleOpenOraichain = () => {
    setModalOpen(true);
    setName(values['oraichain'].name);
    setImage(values['oraichain'].image);
    setCommission(values['oraichain'].commission);
    setValoper(values['oraichain'].valoper);
    setLink(values['oraichain'].link);
  };

  const handleOpenPersistence = () => {
    setModalOpen(true);
    setName(values['persistence'].name);
    setImage(values['persistence'].image);
    setCommission(values['persistence'].commission);
    setValoper(values['persistence'].valoper);
    setLink(values['persistence'].link);
  };  
  
  const handleOpenPlanq = () => {
    setModalOpen(true);
    setName(values['planq'].name);
    setImage(values['planq'].image);
    setCommission(values['planq'].commission);
    setValoper(values['planq'].valoper);
    setLink(values['planq'].link);
  };
  
  const handleOpenProvenance = () => {
    setModalOpen(true);
    setName(values['provenance'].name);
    setImage(values['provenance'].image);
    setCommission(values['provenance'].commission);
    setValoper(values['provenance'].valoper);
    setLink(values['provenance'].link);
  };
  
  const handleOpenStride = () => {
    setModalOpen(true);
    setName(values['stride'].name);
    setImage(values['stride'].image);
    setCommission(values['stride'].commission);
    setValoper(values['stride'].valoper);
    setLink(values['stride'].link);
  };
  
  const handleOpenTerra2 = () => {
    setModalOpen(true);
    setName(values['terra2'].name);
    setImage(values['terra2'].image);
    setCommission(values['terra2'].commission);
    setValoper(values['terra2'].valoper);
    setLink(values['terra2'].link);
  };
  
  const handleOpenWhitewhale = () => {
    setModalOpen(true);
    setName(values['whitewhale'].name);
    setImage(values['whitewhale'].image);
    setCommission(values['whitewhale'].commission);
    setValoper(values['whitewhale'].valoper);
    setLink(values['whitewhale'].link);
  };

  const handleOpenHaqq = () => {
    setModalOpen(true);
    setName(values['haqq'].name);
    setImage(values['haqq'].image);
    setCommission(values['haqq'].commission);
    setValoper(values['haqq'].valoper);
    setLink(values['haqq'].link);
  };  
  
  const handleOpenAndromeda = () => {
    setModalOpen(true);
    setName(values['andromeda'].name);
    setImage(values['andromeda'].image);
    setCommission(values['andromeda'].commission);
    setValoper(values['andromeda'].valoper);
    setLink(values['andromeda'].link);
  };  

  const handleOpenCheqd = () => {
    setModalOpen(true);
    setName(values['cheqd'].name);
    setImage(values['cheqd'].image);
    setCommission(values['cheqd'].commission);
    setValoper(values['cheqd'].valoper);
    setLink(values['cheqd'].link);
  };   
  
  const handleOpenFuryaCSK = () => {
    setModalOpen(true);
    setName(values['furyacsk'].name);
    setImage(values['furyacsk'].image);
    setCommission(values['furyacsk'].commission);
    setValoper(values['furyacsk'].valoper);
    setLink(values['furyacsk'].link);
  };   
  
  const handleOpenFuryaMI = () => {
    setModalOpen(true);
    setName(values['furyami'].name);
    setImage(values['furyami'].image);
    setCommission(values['furyami'].commission);
    setValoper(values['furyami'].valoper);
    setLink(values['furyami'].link);
  };    

  const handleOpenZetachain = () => {
    setModalOpen(true);
    setName(values['zetachain'].name);
    setImage(values['zetachain'].image);
    setCommission(values['zetachain'].commission);
    setValoper(values['zetachain'].valoper);
    setLink(values['zetachain'].link);
  };   
  
  const handleOpenSource = () => {
    setModalOpen(true);
    setName(values['source'].name);
    setImage(values['source'].image);
    setCommission(values['source'].commission);
    setValoper(values['source'].valoper);
    setLink(values['source'].link);
  };   


  const handleOpenNibiru = () => {
    setModalOpen(true);
    setName(values['nibiru'].name);
    setImage(values['nibiru'].image);
    setCommission(values['nibiru'].commission);
    setValoper(values['nibiru'].valoper);
    setLink(values['nibiru'].link);
  };    
  
  const handleOpenNeutaro = () => {
    setModalOpen(true);
    setName(values['neutaro'].name);
    setImage(values['neutaro'].image);
    setCommission(values['neutaro'].commission);
    setValoper(values['neutaro'].valoper);
    setLink(values['neutaro'].link);
  };   
  
  const handleOpenBlockx = () => {
    setModalOpen(true);
    setName(values['blockx'].name);
    setImage(values['blockx'].image);
    setCommission(values['blockx'].commission);
    setValoper(values['blockx'].valoper);
    setLink(values['blockx'].link);
  };   
  
  const handleOpenSeda = () => {
    setModalOpen(true);
    setName(values['seda'].name);
    setImage(values['seda'].image);
    setCommission(values['seda'].commission);
    setValoper(values['seda'].valoper);
    setLink(values['seda'].link);
  };    

  const handleOpenPryzm = () => {
    setModalOpen(true);
    setName(values['pryzm'].name);
    setImage(values['pryzm'].image);
    setCommission(values['pryzm'].commission);
    setValoper(values['pryzm'].valoper);
    setLink(values['pryzm'].link);
  };    

  const handleOpenPenumbra = () => {
    setModalOpen(true);
    setName(values['penumbra'].name);
    setImage(values['penumbra'].image);
    setCommission(values['penumbra'].commission);
    setValoper(values['penumbra'].valoper);
    setLink(values['penumbra'].link);
  };

  const handleOpenRouter = () => {
    setModalOpen(true);
    setName(values['router'].name);
    setImage(values['router'].image);
    setCommission(values['router'].commission);
    setValoper(values['router'].valoper);
    setLink(values['router'].link);
  };

  const handleOpenCrossfi = () => {
    setModalOpen(true);
    setName(values['crossfi'].name);
    setImage(values['crossfi'].image);
    setCommission(values['crossfi'].commission);
    setValoper(values['crossfi'].valoper);
    setLink(values['crossfi'].link);
  };  

  const handleOpenNamada = () => {
    setModalOpen(true);
    setName(values['namada'].name);
    setImage(values['namada'].image);
    setCommission(values['namada'].commission);
    setValoper(values['namada'].valoper);
    setLink(values['namada'].link);
  };  

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  
  return (
    <div className="mainnet-back">
      {modalOpen && (
        <>
          <div className="modalOverlay"></div>
          <div className="modal">
            <div className="modal-content">
              <div className="close_button" onClick={handleCloseModal}>
                <img src={close}></img>
              </div>
              <div className="logo">
                <img src={myimg}></img>
                <div className="name" style={{ color: "#4DA2FE" }}>
                  {name}
                </div>
              </div>
              <div className="commission">Commission : {commission}</div>
              <div className="delegation">
                Delegation Address :<br />
                <span className="address">
                  {valoper}
                </span>
              </div>
              <a href={link} target="_blank">
              <div className="button">
                <div className="text">Delegate Tokens</div>
              </div>
              </a>
            </div>
          </div>
        </>
      )}
      <div className="mainnet">
        <div className="title">Mainnets Supported</div>
        <div className="text">
          We provide Core Infrastructure support along with some Community Tools
          & Services
        </div>
        <div className="widget">

          <div className="item">
            <div className="icon">
              <img src={image1}></img>
            </div>
            <div className="title">Sui Network</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenSui}>
                <div className="button-text">Stake</div>
              </button>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={image3}></img>
            </div>
            <div className="title">Axelar</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenAxelar}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/axelar">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={zetaimage}></img>
            </div>
            <div className="title">Zetachain</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenZetachain}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/zetachain">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>  

          <div className="item">
            <div className="icon">
              <img src={image4}></img>
            </div>
            <div className="title">Terra V2</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenTerra2}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/terra">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={namadaimg}></img>
            </div>
            <div className="title">Namada</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenNamada}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/namada">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>          

          <div className="item">
            <div className="icon">
              <img src={image7}></img>
            </div>
            <div className="title">Stride</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenStride}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/stride">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>


          <div className="item">
            <div className="icon">
              <img src={elysimage}></img>
            </div>
            <div className="title">Elys Network</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenElys}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/elys">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>      
          
          
          <div className="item">
            <div className="icon">
              <img src={sideimage}></img>
            </div>
            <div className="title">Side</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenSide}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/side">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>          


          <div className="item">
            <div className="icon">
              <img src={image6}></img>
            </div>
            <div className="title">Kujira</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenKujira}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/kujira">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>


          <div className="item">
            <div className="icon">
              <img src={image2}></img>
            </div>
            <div className="title">Tenet</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenTenet}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/tenet">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>



          <div className="item">
            <div className="icon">
              <img src={image5}></img>
            </div>
            <div className="title">Mars Protocol</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenMars}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/mars">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>




          <div className="item">
            <div className="icon">
              <img src={penumbraimage}></img>
            </div>
            <div className="title">Penumbra</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenPenumbra}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/penumbra">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>


          <div className="item">
            <div className="icon">
              <img src={image8}></img>
            </div>
            <div className="title">Whitewhale</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenWhitewhale}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/whitewhale">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
          <div className="icon">
            <img src={persistenceimage}></img>
          </div>
          <div className="title">Persistence</div>
          <div className="buttons">
            <button className="button1" onClick={handleOpenPersistence}>
              <div className="button-text">Stake</div>
            </button>
            <Link className="button2" to="/service/persistence">
              <div className="button-text">Services</div>
            </Link>
          </div>
        </div>  

        
      <div className="item">
        <div className="icon">
          <img src={haqqimage}></img>
        </div>
        <div className="title">Haqq</div>
        <div className="buttons">
          <button className="button1" onClick={handleOpenHaqq}>
            <div className="button-text">Stake</div>
          </button>
          <Link className="button2" to="/service/haqq">
            <div className="button-text">Services</div>
          </Link>
        </div>
      </div>  


          <div className="item">
            <div className="icon">
              <img src={image9}></img>
            </div>
            <div className="title">Planq</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenPlanq}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/planq">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={image10}></img>
            </div>
            <div className="title">Canto</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenCanto}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/canto">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={image11}></img>
            </div>
            <div className="title">Acrechain</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenAcrechain}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/acrechain">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={image13}></img>
            </div>
            <div className="title">Oraichain</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenOraichain}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/oraichain">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={image14}></img>
            </div>
            <div className="title">Provenance</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenProvenance}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/provenance">
              <div className="button-text">Services</div>
            </Link>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={image15}></img>
            </div>
            <div className="title">Meme Network</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenMeme}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/meme">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={andromeda}></img>
            </div>
            <div className="title">Andromeda</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenAndromeda}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/andromeda">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={cheqdimage}></img>
            </div>
            <div className="title">Cheqd</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenCheqd}>
                <div className="button-text">Stake</div>
              </button>            
              <Link className="button2" to="/service/cheqd">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>         

          <div className="item">
            <div className="icon">
              <img src={furya}></img>
            </div>
            <div className="title">Furya - CSK</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenFuryaCSK}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/furya">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
            <div className="icon">
              <img src={furya}></img>
            </div>
            <div className="title">Furya - MI</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenFuryaMI}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/furya">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>   


          <div className="item">
            <div className="icon">
              <img src={sourceimage}></img>
            </div>
            <div className="title">Source</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenSource}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/source">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>             
           

          <div className="item">
            <div className="icon">
              <img src={nibiruimage}></img>
            </div>
            <div className="title">Nibiru</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenNibiru}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/nibiru">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>             


          <div className="item">
            <div className="icon">
              <img src={neutaroimage}></img>
            </div>
            <div className="title">Neutaro</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenNeutaro}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/neutaro">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>             
           
   
          <div className="item">
            <div className="icon">
              <img src={blockximage}></img>
            </div>
            <div className="title">Blockx</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenBlockx}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/blockx">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>   
          
          
          <div className="item">
            <div className="icon">
              <img src={sedaimage}></img>
            </div>
            <div className="title">Seda</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenSeda}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/seda">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>     
          
          <div className="item">
            <div className="icon">
              <img src={pryzmimage}></img>
            </div>
            <div className="title">Pryzm</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenPryzm}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/pryzm">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>              
  
          <div className="item">
            <div className="icon">
              <img src={routerimage}></img>
            </div>
            <div className="title">Router Protocol</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenRouter}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/router">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>  
          
          <div className="item">
            <div className="icon">
              <img src={crossfiimg}></img>
            </div>
            <div className="title">Cross Finance</div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenCrossfi}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/crossfi">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>           
                          
        </div>
      </div>
    </div>
  );
};

export default Mainnet;
